import React, { Component } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import PageTransition from 'gatsby-plugin-page-transitions';
import Navigation from "../components/Header/Navigation.js";
import LazyLoad from "../components/repeating_elements/Lazy_Load.js";
import PageBreak from "../components/repeating_elements/page_break.js";
import GetInTouchFooter from "../components/Global/Get_In_Touch_Footer.js";
import Footer from "../components/Footer/Footer.js";
import BasicHero from "../components/Global/Basic_Hero.js";
import Listing from "../components/Service/Listing.js";
import EnterHeader from "../components/repeating_elements/Enter_Header.js";
import WorkWithUs from "../components/Global/Work_With_Us_Button.js";
import GDPR from '../components/Global/GDPR';
// import Loader from '../components/Global/Loader';
import SEO from '../components/seo.js';




class Services_Landing extends Component {
	constructor(props){
		super(props);
		this.state = {
			isLoaded: this.props.pageContext.isLoaded,
			isLazyLoaded: this.props.pageContext.isLazyLoaded,
			isReady: this.props.pageContext.isReady,
			data: this.props.pageContext.data,
			cookieBanner: this.props.pageContext.cookieBanner,
			headerLinks: this.props.pageContext.header,
			footerLinks: this.props.pageContext.footer,
		}
	}
	componentDidMount() {

	}
	render_listing(){
		if(this.state.data){
			return this.state.data[0].acf.pages.map( (item) =>{
				return <LazyLoad><Listing key={item.title} title={item.title} description={item.description} image={item.image.sizes.large} url={item.page_link} /></LazyLoad>;
			});
		}
	}
	render() {
		let page_ready = this.state.isLoaded && this.state.isReady ? "page-ready page-loaded show-text" : "page-loading";
		let data = this.state.data;
		if(data){

			data = this.state.data[0].acf;
			return (
				<ParallaxProvider><PageTransition>
				<GDPR data={this.state.cookieBanner} />

				<SEO
					title={this.state.data[0].yoast_meta.yoast_wpseo_title}
					description={this.state.data[0].yoast_meta.yoast_wpseo_metadesc}
				/>
				<div className="site-contain">
					<Navigation links={this.state.headerLinks} />
					<div className="page-main">
					<WorkWithUs />
					<div className={"page service-page " + page_ready}>
						<BasicHero>
							<h4 className="styling-eyebrow">{data.title}</h4>
							<EnterHeader className="styling-header-1">{data.description}</EnterHeader>
						</BasicHero>
						<div className="grid service-list">
							{this.render_listing()}
						</div>
						<PageBreak />
						<GetInTouchFooter />
						<Footer links={this.state.footerLinks} />
					</div>
				</div>
			</div>
			</PageTransition></ParallaxProvider>
			);
		} else{

			return (
				<div></div>
			);
		}
	}
}

export default Services_Landing;
