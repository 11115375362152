
export default function link_realignment(url) {
	var urls = [
		"https://api.thecharlesnyc.com"
	];
	urls.forEach(function(i){
		url = url.replace(i, "");
	})
	return url;
}
