import React from "react";

import { Link } from 'gatsby';
import { ArrowLink } from "../buttons/Arrow_Link.js";
// import utility_data from "../utilities/browser_information.js";
import BackgroundImage from "../repeating_elements/Background-Image.js";
import link_realignment from "../utilities/link_realignment.js";

const Listing = props => {
	return <div className="listing-item service-listing row">
		<div className="grid-m-6 grid-t-6 grid-d-6 content">
			<h4 className="styling-header-2">{props.title}</h4>
			<p>{props.description}</p>
			<ArrowLink url={link_realignment(props.url)} title="Learn More" />
		</div>
		<div className="grid-m-6 grid-t-10 grid-d-10 photo-contain">
			<Link to={link_realignment(props.url)} title="Go to the service page">
				<div className="photo">
					{/* <div className="frame"></div> */}
					<BackgroundImage url={props.image} alt={props.title} />
				</div>
			</Link>
		</div>
	</div>;
}

export default Listing;
